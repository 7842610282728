import axios from "axios"

const API_KEY = process.env.VUE_APP_FUSION_API_KEY;
const url = `${process.env.VUE_APP_FUSION_API_URL}/company_billing/`;

export default {
  async getContainerTypes() {    
    let res = await axios.get(`${url}container_types?API-KEY=${API_KEY}`);    
    return res.data.data;
  },
  async storeContainerCharge(formData) {  
    formData['API-KEY'] = API_KEY;
    let res = await axios.post(`${url}container_charges`, formData);    
    return res.data.data;
  },
  async getContainerCharges(companyId) {  
    let res = await axios.get(`${url}container_charges?API-KEY=${API_KEY}&companyId=${companyId}`);    
    return res.data.data;
  },
}