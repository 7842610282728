<template>
  <div v-if="containerTypeItems.length > 0">
    <v-data-table :headers="headers" :items="containerTypeItems">
      <template v-slot:item.containerCharge="props">
        <v-edit-dialog
          :return-value.sync="props.item.containerCharge"
          large
          persistent
          @save="save(props.item)"
          @cancel="cancel"
          @open="open"
          @close="close"
        >
          <v-chip dark>{{ renderCurrency(props.item.containerCharge) }}</v-chip>
          <template v-slot:input>
            <div class="mt-4 title">Update Charge</div>
          </template>
          <template v-slot:input>
            <v-text-field
              v-model="props.item.containerCharge"
              label="Edit"
              single-line
              counter
              autofocus
              prefix="£"
            ></v-text-field>
          </template>
        </v-edit-dialog>
      </template>
    </v-data-table>
    <v-snackbar :color="snackColor" v-model="snackbar" :timeout="4000">{{ snackText }}</v-snackbar>
  </div>
</template>

<script>
import ContainerCharges from "@/services/ContainerCharges";

export default {
  props: {
    company: Object,
    loading: Boolean
  },
  data() {
    return {
      pagination: {},
      headers: [
        {
          text: "Container Type",
          align: "start",
          sortable: false,
          value: "name"
        },
        { text: "Charge", value: "containerCharge" }
      ],
      containerTypeItems: [],
      containerTypesDefault: [],
      snackbar: false,
      snackText: ""
    };
  },
  methods: {
    // Currency
    renderCurrency(value) {
      if (value === null) {
        value = 0.0;
      }
      return `£${parseFloat(value).toFixed(2)}`;
    },
    async getContainerTypeData() {
      this.$root.sharedData.startLoading();
      ContainerCharges.getContainerTypes()
        .then(data => {
          this.containerTypesDefault = data;
          this.storeContainerTypes(data);
          this.showSnackbar("success", "Container Types Loaded");
          this.$root.sharedData.finishLoading();
          this.getCompanyContainerChargeData();
          return;
        })
        .catch(err => {
          this.showSnackbar("error", err.Message);
          this.$root.sharedData.finishLoading();
          return;
        });
    },
    async getCompanyContainerChargeData() {
      this.$root.sharedData.startLoading();
      ContainerCharges.getContainerCharges(this.company.id)
        .then(data => {
          this.updateTypesWithCompanyCharges(data);
          this.showSnackbar("success", "Container Charges Loaded for " + this.company.name);
          this.$root.sharedData.finishLoading();
          return;
        })
        .catch(err => {
          this.showSnackbar("error", err.Message);
          this.$root.sharedData.finishLoading();
          return;
        });
    },
    storeContainerTypes(data) {
      data.forEach(containerType => {
        this.containerTypeItems.push({
          container_id: containerType.key,
          name: containerType.text,
          containerCharge: null,
          apiValue: containerType.apiValue
        });
      });
    },
    updateTypesWithCompanyCharges(data) {
      data.forEach(containerCharge => {
        let chargeIndex = this.containerTypeItems.findIndex(type => type.container_id == containerCharge.containerType);
        this.containerTypeItems[chargeIndex].containerCharge = containerCharge.containerCharge
      });
    },
    save(item) {      
      let tempItem = {
        companyId: this.company.id,
        containerType: item.container_id,
        containerCharge: item.containerCharge
      }
      this.$root.sharedData.startLoading();
      ContainerCharges.storeContainerCharge(tempItem)
      .then(() => {
        this.$root.sharedData.finishLoading();
        this.showSnackbar("success", `Container charge for ${item.name} saved successfully.`);
      })
      .catch(err => {
        this.showSnackbar("error", err.Message);
        this.$root.sharedData.finishLoading();
        return;
      });
    },
    cancel() {
      this.showSnackbar("error", "Canceled");
    },
    open() {      
      this.showSnackbar("info", "Dialog opened");
    },
    close() {
      return false;
    },
    showSnackbar(color, text) {
      this.snackbar = true;
      this.snackColor = color;
      this.snackText = text;
    }
  },
  created() {
    this.getContainerTypeData();
  },
  watch: {
    company: function () {
      this.containerTypeItems = [];
      this.storeContainerTypes(this.containerTypesDefault);
      this.getCompanyContainerChargeData();
    }
  }
};
</script>

<style>
</style>