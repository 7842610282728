<template>
  <div class="adhocCharges">
    <div class="hero is-primary">
      <div class="hero-body">
        <div class="container">
          <h1 class="title is-size-1">{{title}}</h1>
        </div>
      </div>
    </div>
    <div class="container">
      <v-card :loading="loading" class="mb-4">
        <v-form class="ma-2" @submit.prevent>
          <v-container>
            <v-row>
              <company-select @chosen="setCompany" v-model="companySelected"></company-select>
            </v-row>
          </v-container>
        </v-form>
      </v-card>
      <v-card v-if="Object.keys(companySelected).length > 0" class="mt-4 mb-4">
        <v-container>
          <v-card-title>Create Container Charge for {{companySelected.name}}</v-card-title>
          <v-row>
            <v-col>
              <container-form :company="companySelected" :loading="loading"></container-form>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </div>
    <v-snackbar v-model="snackbar" :timeout="4000">
      {{ text }}
      <v-btn color="blue" text @click="snackbar = false"></v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import CompanySelect from "../components/forms/CompanySelect";
import ContainerForm from "../components/forms/ContainerForm";

export default {
  components: {
    CompanySelect,
    ContainerForm
  },
  data() {
    return {
      // Title
      title: "Container Charges",
      // Snackbar
      snackbar: false,
      text: "",
      companySelected: {}
    };
  },
  methods: {
    setCompany(company) {
      this.companySelected = company;
    }
  },
  computed: {
    loading: function() {
      return this.$root.sharedData.state.loading;
    }
  }
};
</script>

<style>
</style>